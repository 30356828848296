<template>
  <div>
    <div>
      <a-popover
        trigger="click"
        v-model="visible"
        :getPopupContainer="e => e.parentNode"
        overlayClassName="range-box-pop"
      >
        <div :class="{'range-form-item':true,'range-form-item-edit':isEdit}" :style="visible ? 'border-color:#cf3838' : ''">
          <span v-if="!list?.length" class="range-form-empty">请选择部门或人员（默认全体人员可见）</span>
          <template v-else>
            <span class="range-span" v-for="item in list" :key="item.key">{{item.label}}</span>
          </template>
        </div>
        <template #content>
          <slot />
        </template>
      </a-popover>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  },
  list: {
    type: Array,
    default: () => []
  }
})
const visible = ref(false)
</script>
<style lang="scss" scoped>
.range-form-empty {
  color: #bfbfbf;
  flex: 1;
  line-height: 22px;
  padding-left: 7px;
}
.range-form-item {
  width: 468px;
  min-height: 32px;
  max-height: 100px;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  cursor: pointer;
  &:hover {
    border-color: #cf3838;
  }

  .range-span {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 8px 0 8px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 1px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-edit {
    background: #f5f5f5;
    color: #bfbfbf;
    cursor: unset;
    &:hover {
      border-color: #d9d9d9;
    }
    .range-span {
      border-color: #d9d9d9;
    }
  }
}
</style>
<style lang="scss">
.range-box-pop{
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
    width: 468px;
  }
}
</style>
